import * as React from "react"
import "./LayoutSideBarLeft.css";
import favicon from '@src/images/favicon.ico';
import { Helmet } from "react-helmet"

const LayoutSideBarLeft = ({ sideBar, content }) => {
    return (
        <div className="w-full flex md:p-4 bg-background-gray shadow-lg shadow-white">

            <Helmet>
                <title>WAP Dilhara | Web Application Developer</title>
                <link rel="icon" type="image/png" href={favicon} sizes="32x32"></link>
            </Helmet>

            <div className="md:hidden flex w-full fixed -z-50 h-[60px] items-center justify-between px-4 bg-background-gray"></div>
            <div id="sideBar" className="w-[290px] z-10 md:h-[calc(100vh-2rem)] h-[calc(100vh)] absolute md:relative -left-[290px] md:left-0 bg-stale-gray transition-all ease-in-out"> {sideBar}</div>

            <div className="flex-grow md:h-[calc(100vh-2rem)] h-[calc(100vh)] md:pt-0 pt-[60px] overflow-hidden">{content}</div>
        </div>
    )
}

export default LayoutSideBarLeft;