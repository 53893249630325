import React, { useEffect } from "react"

const ServicesBlock = () => {
    return (
        <div className="flex-row">
            <div className="w-full flex">
                <div className="">
                    <div className="">
                        <h4>My Services</h4>
                    </div>
                </div>
            </div>


            <div className="flex-col grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-fit gap-4">
                <div className="mb-4">
                    <div className="p-4">
                        <h5 className="mb-2"> Web & Mobile Applications Development </h5>

                        <p className="mb-2">
                            I build from simple landing pages to enterprise level web applications, portals, e-commerce, mobile first web apps.
                            I can integrate with a vast range of external services such as pulling latest stock prices from 3rd-party service,
                            processing payments, or live weather data in a certain area etc, bringing all kinds of interesting data to your application.
                        </p>

                        <div>
                            <a href="https://devc9.deviitor.com/shorten/NedP897wGF" className="text-light-yellow">
                                Contact Now
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <div className="p-4">
                        <h5 className="mb-2"> React Native Applications Development </h5>

                        <p className="mb-2">
                            I build mobile apps for Android and iOS using revolutionary mobile development framework “React Native”.
                            The main advantage of using React Native is that the same code can be shared across multiple operating platforms,
                            both Android and iOS. I also integrate these mobile apps with various back-office APIs.
                        </p>

                        <div className="">
                            <a href="https://devc9.deviitor.com/shorten/NedP897wGF" className="text-light-yellow">
                                Contact Now
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <div className="p-4">
                        <h5 className="mb-2"> Gatsby Websites Development </h5>

                        <p className="mb-2">
                            In the Age of Jamstack I think Gatsby is the very best way to obtain High Rankings in Google's Lighthouse. So I build super fast website using JAMstack, Gatsby, Netlify, Contentful, Strapi.
                        </p>

                        <div className="">
                            <a href="https://devc9.deviitor.com/shorten/NedP897wGF" className="text-light-yellow">
                                Contact Now
                            </a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}


export default ServicesBlock;