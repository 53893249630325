import React, { useEffect, useState } from "react"
import avatarImg from '@src/images/avatar.jpg';
import './SideBar.css';
import Circle from "./CircleProgress";
import Line from "./LineProgress";
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@src/utils/font-awesome';

const currentAge = () => {
    return new Date().getFullYear() - 1994;
}

const initScrollBars = () => {
    Scrollbar.use(OverscrollPlugin)

    Scrollbar.init(document.querySelector('#sideBarScroll'), {
        damping: 0.05,
        renderByPixel: true,
        continuousScrolling: true,
    });
}

const SideBar = () => {

    const [active, setActive] = React.useState();

    const toggleSideBar = () => {
        if (active) {
            document.querySelector('#sideBar').classList.remove('active')
            setActive(false)
        }
        else {
            document.querySelector('#sideBar').classList.add('active')
            setActive(true);
        }
    }

    useEffect(() => {
        initScrollBars();
    }, [])

    return (
        <div className="w-full h-full p-4">
            <div className="absolute w-full left-0 top-0 z-50 p-8 h-[235px] text-center shadow-sm bg-gradient-to-r from-[#252532fa] to-[#23232dfa]">

                <div className={`${active ? 'translate-x-[0px]' : 'translate-x-[70px]'} md:hidden absolute top-0 w-full left-0 transition-all ease-in-out`} >
                    <a className="w-[60px] h-[60px] flex items-center justify-center ml-auto mr-0 cursor-pointer" onClick={() => toggleSideBar()}>
                        <FontAwesomeIcon className="text-light-yellow mr-3" icon={'ellipsis-v'} />
                    </a>
                </div>

                {/* <!-- avatar --> */}
                <div className="w-[90px] h-[90px] my-0 mx-auto relative mb-4">
                    <img className="rounded-full" src={avatarImg} />

                    <div className="art-lamp-light">
                        <div className="art-available-lamp"></div>
                    </div>
                </div>

                {/* <!-- name --> */}
                <h5 className="text-ghost-white">WAP Dilhara</h5>
                <div className="text-xs"> Frontend (JS) & <br /> Backend (Larval) Developer</div>
            </div>

            {/* <!-- scroll frame --> */}
            <div id="sideBarScroll" className="overflow-hidden outline-none h-full">
                <div className="pt-[240px] px-4">
                    <div className="py-4">
                        <ul>
                            <li className="flex justify-between">
                                <h6 className="text-sm text-ghost-white">Residence:</h6>
                                <span className="text-sm">Philippines</span>
                            </li>
                            <li className="flex justify-between">
                                <h6 className="text-sm text-ghost-white">City:</h6>
                                <span className="text-sm">NCR South</span>
                            </li>
                            <li className="flex justify-between">
                                <h6 className="text-sm text-ghost-white">Age:</h6>
                                <span className="text-sm">{currentAge()}</span>
                            </li>
                        </ul>
                    </div>
                </div>


                {/* <div className="w-full h-[1px] bg-separate-gray"></div> */}

                {/* soft skills */}
                <div className="soft-skills grid grid-cols-3 gap-4 py-6 px-4 text-center">
                    <Circle container="circle-1" title="Laravel" progress="0.91" />
                    <Circle container="circle-2" title="JavaScript" progress="0.92" />
                    <Circle container="circle-3" title="React Native" progress="0.9" />
                </div>

                <div className="w-full h-[1px] bg-separate-gray"></div>

                {/* hard skills */}
                <div className="hard-skills grid grid-rows-3 gap-5 py-6 text-center">
                    <Line container="line-1" title="Server Management" progress="0.85" />
                    <Line container="line-2" title="AWS, Google Cloud " progress="0.89" />
                    <Line container="line-3" title="Git" progress="0.9" />
                    <Line container="line-4" title="Vue" progress="0.9" />
                    <Line container="line-5" title="Gatsby" progress="0.88" />
                    <Line container="line-6" title="Tailwind CSS" progress="0.92" />
                    <Line container="line-7" title="JAMStack" progress="0.89" />
                </div>

                <div className="w-full h-[1px] bg-separate-gray"></div>

                {/* other skills */}
                <div className="other-skills flex flex-row py-6 text-center">
                    <ul className="w-full text-left">
                        <li className="mb-1">
                            <FontAwesomeIcon className="text-light-yellow mr-3" icon={'check'} /> <span> Collaboration</span>
                        </li>
                        <li className="mb-1">
                            <FontAwesomeIcon className="text-light-yellow mr-3" icon={'check'} />  <span>Creativity</span>
                        </li>
                        <li className="mb-1">
                            <FontAwesomeIcon className="text-light-yellow mr-3" icon={'check'} /> <span>Work Ethic</span>
                        </li>
                        <li className="mb-1">
                            <FontAwesomeIcon className="text-light-yellow mr-3" icon={'check'} />  <span>Enthusiasm</span>
                        </li>
                    </ul>
                </div>

                <div className="w-full h-[1px] bg-separate-gray"></div>

                <div className="py-4">
                    {/* <a href="#" className="uppercase" download>Download CV  <FontAwesomeIcon icon={'download'} /></a> */}
                </div>
            </div>
            <div className="flex absolute left-0 bottom-0 px-8 justify-between h-[50px] w-full items-center bg-gradient-to-r from-[#252532fa] to-[#23232dfa]">
                <a href="#." target="_blank"><FontAwesomeIcon icon={'circle'} /></a>
                <a href="#." target="_blank"><FontAwesomeIcon icon={'circle'} /></a>
                <a href="#." target="_blank"><FontAwesomeIcon icon={'circle'} /></a>
                <a href="#." target="_blank"><FontAwesomeIcon icon={'circle'} /></a>
            </div>
        </div>
    )
}

export default SideBar;