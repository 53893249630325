import React, { useEffect } from "react"
import './scss/style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@src/utils/font-awesome';
import './ContactBlock.css';

const ContactBlock = () => {
    return (
        <div className="w-full max-w-sm">

            <div className="art-section-title">
                <div className="art-title-frame">
                    <h4>Contact information</h4>
                </div>
            </div>

            <div className="col-lg-4">
                <div className="art-a art-card">
                    <div className="art-table p-15-15">
                        <ul>
                            <li>
                                <FontAwesomeIcon className="text-light-yellow" icon={'envelope'} />
                                <a href="mailto:keenaxe22@gmail.com"> keenaxe22@gmail.com</a>
                            </li>
                            <li>
                                <FontAwesomeIcon className="text-light-yellow" icon={'comment'} />
                                <a href="https://wa.me/639811898829"> Whatsapp</a>
                            </li>
                            <li>
                                <FontAwesomeIcon className="text-light-yellow" icon={'phone'} />
                                <a href="tel:+639811898829"> +63 981 189 8829 </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ContactBlock;