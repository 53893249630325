import * as React from "react"
import LayoutSideBarLeft from "@deviitor/layouts/LayoutSideBarLeft";
import SideBar from "@deviitor/components/SideBar";
import ContentPage from "@deviitor/components/ContentPage";


const TestPage = () => {
    return (
        <LayoutSideBarLeft sideBar={<SideBar />} content={<ContentPage />} />
    )
}
export default TestPage;