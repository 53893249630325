import React, { useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'swiper/css/bundle';
import './scss/style.scss'
import '@src/utils/font-awesome';
import Swiper, { Navigation, Pagination } from 'swiper';
import './FeedbackBlock.css'
import richy365 from '@src/images/richy365.jpeg';
import hiramfromthechi from '@src/images/hiramfromthechi.png';
import rowen40 from '@src/images/rowen40.jpeg';

const initSlider = () => {
    new Swiper('.art-testimonial-slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 30,
        speed: 1400,
        autoplay: false,
        autoplaySpeed: 5000,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.art-testi-swiper-next',
            prevEl: '.art-testi-swiper-prev',
        },
        breakpoints: {
            1500: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 1,
            },
        },
    });
}

const FeedbackBlock = () => {

    useEffect(() => {
        initSlider();

        return () => {
        }
    }, [])


    return (
        <div className="w-full">
            <div className="row">

                <div className="flex w-full">


                    <div className="art-section-title">

                        <div className="art-title-frame">

                            <h4>Recommendations</h4>
                        </div>

                    </div>


                </div>

                <div className="flex w-full">
                    <div className="swiper-container art-testimonial-slider overflow-visible">

                        <div className="swiper-wrapper">

                            <div className="swiper-slide">


                                <div className="art-a art-testimonial">

                                    <div className="testimonial-body">

                                        <img className="art-testimonial-face" src={richy365} alt="face" />

                                        <h5>richy365</h5>
                                        {/* <div className="art-el-suptitle mb-15">Template author</div> */}

                                        <div className="mb-15">Excellent service. He got my website and app online and working smoothly. Communication was excellent. Would recommend.</div>


                                        <div className="art-testimonial-footer">
                                            <div className="art-left-side">

                                                <ul className="art-star-rate">
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                </ul>

                                            </div>
                                            <div className="art-right-side">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">


                                <div className="art-a art-testimonial">

                                    <div className="testimonial-body">

                                        <img className="art-testimonial-face" src={rowen40} alt="face" />

                                        <h5>rowen40</h5>
                                        {/* <div className="art-el-suptitle mb-15">Template author</div> */}

                                        <div className="mb-15">This guy is the best. Superfast delivery! Excellent service! Will come back for sure! Thanks again</div>


                                        <div className="art-testimonial-footer">
                                            <div className="art-left-side">

                                                <ul className="art-star-rate">
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                </ul>

                                            </div>
                                            <div className="art-right-side">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">


                                <div className="art-a art-testimonial">

                                    <div className="testimonial-body">

                                        <img className="art-testimonial-face" src={hiramfromthechi} alt="face" />

                                        <h5>hiramfromthechi</h5>
                                        {/* <div className="art-el-suptitle mb-15">Template author</div> */}

                                        <div className="mb-15">Dedicated as always, and super helpful. Will not quit until the problem is solved and communicates effectively. Always looking forward to working with </div>


                                        <div className="art-testimonial-footer">
                                            <div className="art-left-side">

                                                <ul className="art-star-rate">
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                    <li><FontAwesomeIcon className="text-light-yellow" icon={'star'} /> </li>
                                                </ul>

                                            </div>
                                            <div className="art-right-side">

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="flex w-full relative">

                    <div className="art-slider-navigation">

                        <div className="art-sn-left">

                            <div className="swiper-pagination"></div>

                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}


export default FeedbackBlock;