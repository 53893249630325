import React, { useEffect } from "react"
import './SideBar.css';
import ProgressBar from 'progressbar.js';

const loadProgress = (container, progress) => {
    const bar = new ProgressBar.Line("#" + container, {
        strokeWidth: 1.72,
        easing: 'easeInOut',
        duration: 1400,
        delay: 2800,
        trailWidth: 1.72,
        svgStyle: {
            width: '100%',
            height: '100%'
        },
        step: (state, bar) => {
            bar.setText(Math.round(bar.value() * 100) + ' %');
        }
    });
    bar.animate(progress);
}

const Line = ({ container, title, progress }) => {

    useEffect(async () => {
        loadProgress(container, progress);
    }, [])

    return (
        <div className="progress-item relative">
            <h6 className="mb-2 text-left">{title}</h6>
            <div id={container}></div>
        </div>
    )
}


export default Line;