import React, { useEffect } from "react"
import workImg1 from '@src/images/1.jpg';
import './scss/style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@src/utils/font-awesome';
import { StaticImage as Img } from 'gatsby-plugin-image'
import './WorksBlock.css';

const WorksBlock = () => {
    return (
        <div className="w-full">

            <div className="row p-30-0">

                <div className="col-lg-12">

                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Works</h4>
                        </div>
                        {/* <div className="art-right-frame">

                            <div className="art-filter">

                                <a href="#" data-filter="*" className="art-link art-current">All Categories</a>

                                <a href="#" data-filter=".webTemplates" className="art-link">Web Templates</a>

                                <a href="#" data-filter=".logos" className="art-link">Logos</a>

                                <a href="#" data-filter=".drawings" className="art-link">Drawings</a>

                                <a href="#" data-filter=".ui" className="art-link">UI Elements</a>
                            </div>

                        </div> */}

                    </div>


                </div>


                <div className="art-grid art-grid-3-col art-gallery grid grid-cols-3 ">

                    <div className="art-grid-item webTemplates w-full">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/factsandfakes.png" alt="dilhara-works-gatsby" layout="fullWidth" />
                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">Facts & Fakes 2</h5>

                            <h6 className="mb-15">Gatsby</h6>

                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/grands-meres.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">grands-meres.net</h5>
                            <h6 className="mb-15">Gatsby</h6>

                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/sunrukitchen.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">Landing page for stainless steel</h5>
                            <h6 className="mb-15">Gatsby</h6>

                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/landslide-monitoring.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">Landslide Monitoring</h5>
                            <h6 className="mb-15">Vue + Laravel</h6>
                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/arl.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">ARL Group</h5>
                            <h6 className="mb-15">WordPress</h6>
                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>



                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/distribution-management-system.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">Distribution Management System</h5>
                            <h6 className="mb-15">Vue + Laravel</h6>
                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/loyalty-reward-system.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">Loyalty Reward System</h5>
                            <h6 className="mb-15">Vue + Laravel</h6>
                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/sfa-app.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">Sales Force Automation App</h5>
                            <h6 className="mb-15">React Native</h6>
                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                    <div className="art-grid-item webTemplates">

                        <a data-fancybox="gallery" className="art-a art-portfolio-item-frame art-horizontal">

                            <Img src="'./../../../../../src/images/work/wrestlingworld.png" alt="dilhara-works-gatsby" layout="fullWidth" />

                        </a>


                        <div className="art-item-description">

                            <h5 className="mb-15">wrestlingworld.co blog</h5>
                            <h6 className="mb-15">WordPress</h6>
                            {/* <a href="#." className="art-link art-color-link art-w-chevron">Read more</a> */}
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}


export default WorksBlock;