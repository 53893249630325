import React, { useEffect } from "react"
import workImg1 from '@src/images/1.jpg';
import './scss/style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@src/utils/font-awesome';
import './HistoryBlock.css';

const HistoryBlock = () => {
    return (
        <div className="w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="w-full">

                    <div className="art-section-title">
                        <div className="art-title-frame">

                            <h4>Education</h4>
                        </div>
                    </div>

                    <div className="art-timeline art-gallery" id="history">
                        <div className="art-timeline-item">
                            <div className="art-timeline-mark-light"></div>
                            <div className="art-timeline-mark"></div>

                            <div className="art-a art-timeline-content">
                                <div className="art-card-header">
                                    <div className="art-left-side">
                                        <h5>B.S.c Major in Information Technology</h5>
                                        <div className="art-el-suptitle mb-15">University of Colombo</div>
                                    </div>
                                    <div className="art-right-side">
                                        <span className="art-date">2015 - 2018</span>
                                    </div>
                                </div>

                                <p>
                                    Followed my passion in a core of prototyping and cloud computing classes.
                                    Excelled in user experience and user interfaces.
                                </p>
                                {/* <a data-fancybox="diplome" href="files/certificate.jpg" className="art-link art-color-link art-w-chevron">Diplome</a> */}
                            </div>
                        </div>

                        <div className="art-timeline-item">
                            <div className="art-timeline-mark-light"></div>
                            <div className="art-timeline-mark"></div>

                            <div className="art-a art-timeline-content">
                                <div className="art-card-header">
                                    <div className="art-left-side">
                                        <h5>G.C.E. Advance Level</h5>
                                        <div className="art-el-suptitle mb-15">Nalanda College - Colombo 10</div>
                                    </div>
                                    <div className="art-right-side">
                                        <span className="art-date"> 2011 - 2013</span>
                                    </div>
                                </div>
                                <div>
                                    Acquired with two very good passes and one credit pass (Z- 1.5)
                             </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="w-full">

                    <div className="art-section-title">
                        <div className="art-title-frame">
                            <h4>Work History</h4>
                        </div>
                    </div>

                    <div className="art-timeline">
                        <div className="art-timeline-item">
                            <div className="art-timeline-mark-light"></div>
                            <div className="art-timeline-mark"></div>


                            <div className="art-a art-timeline-content">
                                <div className="art-card-header">
                                    <div className="art-left-side">
                                        <h5>Software Engineer</h5>
                                        {/* <div className="art-el-suptitle mb-15">Modifica</div> */}
                                    </div>
                                    <div className="art-right-side">
                                        <span className="art-date"> 2019 - 2022</span>
                                    </div>
                                </div>
                                {/* <p> */}
                                <ul>
                                    <li>
                                        • Full stack web developer responsible for end-to-end web app development and deployment.
                                        </li>

                                    <li>
                                        • Increased user engagement of live irrigation level monitoring system
                                        </li>

                                    <li>
                                        • Increased Sales by 16% by building a fast responsive Sales Force Automation App and Back Office functionality
                                        </li>

                                    <li>
                                        • Boosted user experience by 68% by developing a Rich UI for rainfall monitoring system.
                                        </li>
                                </ul>
                                {/* </p> */}
                            </div>
                        </div>

                        <div className="art-timeline-item">
                            <div className="art-timeline-mark-light"></div>
                            <div className="art-timeline-mark"></div>


                            <div className="art-a art-timeline-content">
                                <div className="art-card-header">
                                    <div className="art-left-side">
                                        <h5>Associate Software Engineer</h5>
                                        {/* <div className="art-el-suptitle mb-15">Modifica</div> */}
                                    </div>
                                    <div className="art-right-side">
                                        <span className="art-date"> 2018 - 2019</span>
                                    </div>
                                </div>
                                {/* <p> */}
                                <ul>
                                    <li>
                                        • Designing customer web forms for internet traffic to inquire about products and services.
                                        </li>

                                    <li>
                                        • Performed maintenance and updates to existing client Web sites
                                        </li>

                                    <li>
                                        • Addressed cross-browser compatibility issues.
                                        </li>

                                    <li>
                                        • Investigating, fixing, and debugging software defects.
                                        </li>
                                </ul>
                                {/* </p> */}
                            </div>
                        </div>

                        <div className="art-timeline-item">
                            <div className="art-timeline-mark-light"></div>
                            <div className="art-timeline-mark"></div>

                            <div className="art-a art-timeline-content">
                                <div className="art-card-header">
                                    <div className="art-left-side">
                                        <h5>Freelance Web Developer</h5>
                                        <div className="art-el-suptitle mb-15"></div>
                                    </div>
                                    <div className="art-right-side">
                                        <span className="art-date"> 2016 - 2018</span>
                                    </div>
                                </div>
                                {/* <p> */}
                                <ul>
                                    <li>
                                        • Bulk SMS sender for Windows PC/Servers.
                                        </li>
                                    <li>
                                        • PHP protocol for reading SMS from windows.
                                    </li>
                                    <li>
                                        • Managed Linux servers using shell access for client websites.
                                    </li>
                                    <li>
                                        • Meeting with the prospective clients to review website, and gather the client’s specifications for new or existing websites.
                                    </li>
                                </ul>
                                {/* </p> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default HistoryBlock;