import React, { useEffect } from "react"
import './SideBar.css';
import ProgressBar from 'progressbar.js';

const loadProgress = (container, progress) => {
    const bar = new ProgressBar.Circle("#" + container, {
        strokeWidth: 6,
        easing: 'easeInOut',
        duration: 1400,
        delay: 2800,
        trailWidth: 6,
        svgStyle: {
            width: '100%',
            height: '100%'
        },
        step: (state, bar) => {
            bar.setText(Math.round(bar.value() * 100) + ' %');
        }
    });
    bar.animate(progress);
}

const Circle = ({ container, title, progress }) => {

    useEffect(async () => {
        loadProgress(container, progress);
    }, [])

    return (
        <div className="progress-item relative">
            <div id={container}></div>
            <h6 className="mt-3">{title}</h6>
        </div>
    )
}


export default Circle;