import React, { useEffect } from "react"
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import Typed from 'typed.js';
import faceImage from '@src/images/face.png';
import coverImage from '@src/images/cover.jpg';
import ServicesBlock from './ServicesBlock'
import PricesBlock from "./PricesBlock";
import FeedbackBlock from "./FeedbackBlock";
import './ContentPage.css'
import WorksBlock from "./WorksBlock";
import HistoryBlock from "./HistoryBlock";
import ContactBlock from "./ContactBlock";

const initScrollBars = () => {
    Scrollbar.use(OverscrollPlugin)

    Scrollbar.init(document.querySelector('#contentPageScroll'), {
        damping: 0.05,
        renderByPixel: true,
        continuousScrolling: true,
    });
}

const initTyping = (typed) => {
    const options = {
        strings: ['Web Applications', 'React Native Apps', 'Lumen APIs', 'Gatsby Websites'],
        typeSpeed: 100,
        backSpeed: 50,
        loop: true,
        showCursor: true,
    };

    typed = new Typed(document.querySelector('#contentPageTyping'), options);

}

const typed = null;

const ContentPage = () => {

    useEffect(() => {
        initScrollBars();
        initTyping(typed);

        return () => {
            typed.destroy();
        }
    }, [])
    return (
        <div className="w-full h-full p-4 max-w-fit">
            <div id="contentPageScroll" className="overflow-hidden outline-none h-full">
                <div className="w-full px-4 md:px-6 relative overflow-hidden">
                    <div className="bg-[url('@src/images/cover.jpg')] bg-center bg-cover relative">
                        <div className="relative flex p-8 w-full bg-gradient-to-r from-[#252532fa] to-[#23232d00]">

                            <div className="w-full self-center">

                                <h1 className="mb-8 text-[2.5rem] font-bold">Passionate about building  <br />world class Web Apps!</h1>

                                <div className="mb-8 text-ghost-white">
                                    &lt;<i className="text-light-yellow">code</i>&gt;
                                        I Build &nbsp;
                                        <span id="contentPageTyping"></span>
                                        &lt;/<i className="text-light-yellow">code</i>&gt;
                                    </div>
                                <div className="flex flex-col md:flex-row">

                                    <a href="#works" className="inline-flex h-[45px] px-6 md:mr-4 mb-4 md:mb-auto max-w-[200px] self-center uppercase bg-light-yellow
                                     text-background-gray justify-center content-center hover:-translate-y-0.5 ease-in-out">
                                        <span className="self-center">Explore Now</span>
                                    </a>

                                    {/* <a href="https://devc9.deviitor.com/shorten/NedP897wGF" className="text-ghost-white uppercase self-center">Hire me</a> */}
                                </div>
                            </div>

                            {/* <img src={faceImage} className="w-[225px] absolute right-[30px] bottom-0 hidden lg:block" alt="WAP DILHARA" /> */}
                        </div>
                    </div>
                </div>

                <div className="w-full px-6 max-w-fit">
                    <div className="flex-col pt-6 grid grid-cols-2 md:grid-cols-4">
                        <div className="flex align-middle relative mb-4">
                            <div className="min-w-[60px]">
                                <span className="text-light-yellow text-[1.3rem]">6</span>
                                <span className="text-light-yellow text-[1.3rem]">+</span>
                            </div>
                            <h6 className="self-center">Years Experience</h6>
                        </div>

                        <div className="flex align-middle relative mb-4">
                            <div className="min-w-[60px]">
                                <span className="text-light-yellow text-[1.3rem]">100</span>
                                <span className="text-light-yellow text-[1.3rem]">+</span>
                            </div>
                            <h6 className="self-center">Projects Live</h6>
                        </div>

                        <div className="flex align-middle relative mb-4">
                            <div className="min-w-[60px]">
                                <span className="text-light-yellow text-[1.3rem]">20</span>
                                <span className="text-light-yellow text-[1.3rem]">+</span>
                            </div>
                            <h6 className="self-center">Customers</h6>
                        </div>

                        <div className="flex align-middle relative mb-4">
                            <div className="min-w-[60px]">
                                <span className="text-light-yellow text-[1.3rem]">8%</span>
                                <span className="text-light-yellow text-[1.3rem]">+</span>
                            </div>
                            <h6 className="self-center">Avg. Sales Increased</h6>
                        </div>
                    </div>
                </div>

                <div className="w-full px-6">
                    <ServicesBlock />
                </div>

                {/* <div className="w-full px-6">
                    <PricesBlock />
                </div> */}

                <div className="w-full px-6">
                    <FeedbackBlock />
                </div>

                <div className="w-full px-6">
                    <WorksBlock />
                </div>

                <div className="w-full px-6">
                    <HistoryBlock />
                </div>

                <div className="w-full px-6">
                    <ContactBlock />
                </div>
            </div>
        </div>
    )
}

export default ContentPage;